import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EstadisticasService {

  constructor(private httpservice: HttpService) { }


  getClicksVisit(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/clicks/visit`, params);
  }

  getSales(date,store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/orders/total`, params);
  }

  getProducts(date, store){

  }

  sellersList(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/orders/sellers/list`, params);
  }


  getVisitasTienda(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/clicks/seller`, params);
  }

  getCLickSeller(date,store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/clicks/seller`, params);
  }

  getOrderList(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/orders/list`, params);
  }

  getRanksOld(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/store/ranksold`, params);
  }

  getLastOrder(store){
    let params: HttpParams = new HttpParams();
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/orders/getlast`, params);
  }

  getBestSellerProducts(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    params = params.append('take', String(10));
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/products/bestsellers`, params);
  }

  getInfoProducts(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    params = params.append('take', String(10));
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/statistics/products/lastsold`, params);
  }



  //obtener views de todos los tipos
  getFullCounters(data:any){
    return this.httpservice.post(`admin/viewcounter/getFull`, data);
  }

  getProductsVisit(date,store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    params = params.append('sort', 'desc');
    params = params.append('paginate',  String(10));
    params = params.append('page', String(1))
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/viewcounter/products`,params);
  }

  //generar visit a type
  addVisitByType(data){
      return this.httpservice.post(`viewcounter`, data);
  }
  
  //list por fecha
  visitsList(date, store){
    let params: HttpParams = new HttpParams();
    params = params.append('from', date.startDate);
    params = params.append('until', date.endDate);
    store && ( params = params.append('id_store', store) )
    return this.httpservice.get(`admin/viewcounter/get/date`, params);
  }






}
