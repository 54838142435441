import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class ToppingService {

  private reloadPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private topping: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private httpService: HttpService,
  ) { }

  setTopping(topping){
    this.topping.next(topping)
  }

  getTopping(){
    return this.topping
  }

  getListPage(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('paginate', String(30))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/toppings/list`, params)
  }

  getListPageAdmin(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/toppings-admin/list`, params)
  }


  setReload(){
    this.reloadPage.next(true)
  }

  getReload(): Observable<any>{
    return this.reloadPage
  }


  getFullListPage() : Promise<any> {
    return this.httpService.get(`admin/toppings/fullList`)
  }

  getFullListPageAdmin(): Promise<any>{
    return this.httpService.get(`admin/toppings-admin/fullList`)
  }

  getSimpleFullListPage() : Promise<any> {
    return this.httpService.get(`admin/toppings/simplefullList`)
  }

  getSimpleFullListPageAdmin(): Promise<any>{
    return this.httpService.get(`admin/toppings-admin/simplefullList`)
  }

  getProductBySku(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/toppings/${sku}/detail`);
  }

  getProductBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/toppings-admin/${sku}/detail`);
  }

  updateStatusById(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/toppings/${id}/status`, {status: status});
  }

  updateStatusByIdAdmin(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/toppings-admin/${id}/status`, {status: status});
  }

  add(topping: object) : Promise<any> {
    return this.httpService.post(`admin/toppings`, topping);
    // return this.httpService.put(`admin/products/sku/topping`, topping);
  }
  remove(productSku:string, topping_id:string) : Promise<any> {
    return this.httpService.put(`admin/products/${productSku}/topping/${topping_id}`);
  }

  addAdmin(topping: object): Promise<any> {
    return this.httpService.post('admin/toppings-admin', topping)
  }

  update(id:string, data:object){
    return this.httpService.post(`admin/toppings/${id}/update`, data)
  }

  updateAdmin(id:string, data:object){
    return this.httpService.post(`admin/toppings-admin/${id}/update`, data)
  }

  deleteCategory(id:string, option:string): Promise<any>{
    return this.httpService.delete(`admin/toppings/${id}/${option}`)
  }

  removeCtgFromFather(ctgFather:string, ctgSon:string): Promise<any>{
    return this.httpService.post(`admin/toppings/${ctgFather}/remove/${ctgSon}`)
  }

}
