<app-modal #modalComponent>
    <div class="w-full max-w-md lg:px-4 pb-2">
        <h5>
           {{'create-topping.seleccione' | translate}}
        </h5>
     
        <div class="mt-2 flex flex-wrap gap-3">
            <button *ngFor="let item of list" (click)="selectedTopping(item)" class="badge badge-category badge-rounded outline-border border-2 border-transparent badge-sm flex items-center gap-2" >
                <span class="font-extrabold">{{item.name}}</span>
                <span >$ {{item.price}}</span>
            </button>
        </div>
        
    
        <div class="mt-6 lg:mt-10 flex justify-end">
            <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4 uppercase"> {{'buttons.cerrar' | translate}} </button>
            <!-- <button (click)="close()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button> -->
        </div>
    </div>
</app-modal>
