import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
@Injectable({
  providedIn: 'root'
})
export class VariantesService {

  private dataProperty: BehaviorSubject<any> = new BehaviorSubject({});
  private is_editing: BehaviorSubject<any> = new BehaviorSubject(false);
  private first: BehaviorSubject<any> = new BehaviorSubject(true);


  constructor(
    private httpService : HttpService
  ) {}

  setProperty(data){
    this.dataProperty.next(data)
  }

  getProperty(): Observable<any>{
    return this.dataProperty
  }

  setEdit(data){
    this.is_editing.next(data)
  }

  getEdit(): Observable<any>{
    return this.is_editing
  }

  setFirstTime(data){
    this.first.next(data)
  }

  getFirstTime(): Observable<any>{
    return this.first
  }

  getList(){
    return this.httpService.get('admin/properties/get')
  }

  saveProperty(data){
    return this.httpService.post('admin/properties/new', data)
  }

  updateProperty(data){
    return this.httpService.post('admin/properties/update', data)
  }

  deleteProp(id){
    return this.httpService.delete(`admin/properties/delete/${id}`)
  }

}
