import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TagsService } from 'src/app/service/tags/tags.service';

import { ProductosService } from 'src/app/service/productos/productos.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';
import { StorageService } from 'src/app/service/storage/storage.service';
import { TagsComponent as tagsComponent } from '../../../tags/modals/tags/tags.component';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit, OnDestroy {

  current_page: number = 1;
  list : any[] = [];
  listNew : any[] = [];
  previousPage: number = 0;
  listFinish = false;

  @ViewChild('modalComponent') modal:
  | ModalComponent<tagsComponent>
  | undefined;
  private subscriptions: Array<Subscription> = [];

  role:string = ''

  constructor(
    private tagsService: TagsService,
    private cd: ChangeDetectorRef,
    private productService: ProductosService,
    private storage: StorageService,
    private spinner: SpinnerService,

  ) {  
      this.getRole(); 
      this.spinner.show()
    }

  ngOnInit(){
    this.loadTags()
   }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectedTag(tag){
    this.productService.setTags([{id: tag.id, name: tag.name,color:tag.color}])
    this.close()
  }

  getRole(){
    this.subscriptions.push(
      this.storage.getRole()
      .subscribe((rol:string) => rol !== '' && (this.role = JSON.parse(rol)) )
    )
  }


  loadTags(){
    if(this.role === 'store'){
      this.tagsService.getFullListPage()
      .then(res => {
          this.list = res.response.sort((a,b)=>{
            return a.name - b.name
          })
          this.spinner.hide()
          this.cd.detectChanges()
        })
    }else{
      this.tagsService.getFullListPageAdmin()
      .then(res => {
        this.list = res.response
        this.spinner.hide()
        this.cd.detectChanges()
      })
    }
  }


  async close(): Promise<void> {
    await this.modal?.close();
  }

}
