<app-modal #modalComponent>
    <div class="w-full max-w-md lg:px-4 pb-2">
        <h5>
            Seleccione una etiqueta
        </h5>
     
        <div class="mt-2 flex flex-wrap gap-3">
            <p *ngFor="let item of list" (click)="selectedTag(item)" class="w-14 h-14 flex-none rounded-full flex items-center justify-center text-sm font-bold p-2 text-center leading-3 text-white cursor-pointer" [ngClass]="item.selected ? 'outline-border border-2' : ''" [ngStyle]="{'background':item.color}">
                <span >{{item.name}}</span>
            </p>
        </div>
        
    
        <div class="mt-6 lg:mt-10 flex justify-end">
            <button (click)="close()" type="button" class="btn btn-link btn-sm mr-4 uppercase"> {{'buttons.cerrar' | translate}} </button>
            <!-- <button (click)="close()" type="button" class="btn btn-principal">{{'buttons.save' | translate}}</button> -->
        </div>
    
        </div>
</app-modal>
