import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class EnviosService {

  private reloadPage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private category: BehaviorSubject<any> = new BehaviorSubject({});

  constructor(
    private httpService: HttpService,
  ) { }

  setCategory(category){
    this.category.next(category)
  }

  getCategory(){
    return this.category
  }

  getListPage(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/zones/list`, params)
  }

  getListPageAdmin(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/zones-admin/list`, params)
  }

  getListPageZoneStore(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);
    return this.httpService.get(`admin/zones/listStore`, params)
  }

  setReload(){
    this.reloadPage.next(true)
  }

  getReload(): Observable<any>{
    return this.reloadPage
  }


  getFullListPage() : Promise<any> {
    return this.httpService.get(`admin/zones/fullList`)
  }

  getFullListPageAdmin(): Promise<any>{
    return this.httpService.get(`admin/zones-admin/fullList`)
  }

  getProductBySku(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/zones/${sku}/detail`);
  }

  getProductBySkuAdmin(sku: string ) : Promise<any> {
    return this.httpService.get(`admin/zones-admin/${sku}/detail`);
  }

  updateStatusById(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/zones/${id}/status`, {status: status});
  }

  updateStatusByIdAdmin(id: string, status: string) : Promise<any> {
    return this.httpService.put(`admin/zones-admin/${id}/status`, {status: status});
  }

  add(category: object) : Promise<any> {
    return this.httpService.post(`admin/zones`, category);
  }

  addAdmin(category: Object): Promise<any> {
    return this.httpService.post('admin/zones-admin', category)
  }

  update(id:string, data:object){
    return this.httpService.post(`admin/zones/${id}/update`, data)
  }

  updateAdmin(id:string, data:object){
    return this.httpService.post(`admin/zones-admin/${id}/update`, data)
  }

  deleteCategory(id:string, option:string): Promise<any>{
    return this.httpService.delete(`admin/zones/${id}/${option}`)
  }
  deleteCategoryAdmin(id:string, option:string): Promise<any>{
    return this.httpService.delete(`admin/zones-admin/${id}/${option}`)
  }

 

  removeCtgFromFather(ctgFather:string, ctgSon:string): Promise<any>{
    return this.httpService.post(`admin/zones/${ctgFather}/remove/${ctgSon}`)
  }

}
