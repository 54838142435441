import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class pdfTemplatesService {

  private pdfTemplate: BehaviorSubject<any> = new BehaviorSubject({});
  private reloadPage: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private httpService: HttpService,
  ) { }

  setPdfTemplate(pdfTemplate){
    this.pdfTemplate.next(pdfTemplate)
  }

  getPdfTemplate(){
    return this.pdfTemplate
  }

  setReload(){
    this.reloadPage.next(true)
  }

  getReload(): Observable<any>{
    return this.reloadPage
  }

  getListPage(page: number = 1, status: string = "", search: string = "") : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('status', status)
    .append('search', search);

    return this.httpService.get(`admin/templates/list`, params)
  }

  getFullListPage() : Promise<any> {
    return this.httpService.get(`admin/templates/fullList`)
  }

  setTemplate(id_store:string,template:object): Promise<any> {
    return this.httpService.post(`templates/${id_store}/set`, template)
  }

  addTemplate(templates: object): Promise<any> {
    return this.httpService.post('admin/templates', templates)
  }

  updateTemplate(id:string, data:object){
    return this.httpService.post(`admin/templates/${id}/update`, data)
  }

  deleteTemplate(id:string): Promise<any>{
    return this.httpService.post(`admin/templates/${id}/delete`)
  }

}
