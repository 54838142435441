import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  constructor(private httpservice: HttpService) { }

  getList(page: number = 1,paginate:number=10) : Promise<any> {
    return this.httpservice.get(`admin/shares/list`, {page, paginate})
  }

  store(data: any, type:string) : Promise<any> {
    console.log({data,type})
    if(type == 'whatsapp') return this.httpservice.post2(`admin/shares`, {id_share_type: 'sharewhatsapp',  json : data})
    if(type == 'facebook') return this.httpservice.post2(`admin/shares`, {id_share_type: 'sharefacebook',  json : data})
    if(type == 'instagram') return this.httpservice.post2(`admin/shares`, {id_share_type: 'shareinstagram',  json : data})
  }

  delete(store_id:any,share_id: number) : Promise<any> {
    return this.httpservice.post(`admin/shares/${store_id}/trash`,{id:String(share_id)})
  }

}
