import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ArtistService } from 'src/app/service/artists/artists.service';

import { ProductosService } from 'src/app/service/productos/productos.service';
import { ModalComponent } from 'src/app/shared/modal/modal/modal.component';
import { StorageService } from 'src/app/service/storage/storage.service';
import { ArtistsComponent as artistComponent } from '../../../artists/modals/artists/artists.component';
import { SpinnerService } from 'src/app/service/spinner/spinner.service';

@Component({
  selector: 'app-artists',
  templateUrl: './artists.component.html',
  styleUrls: ['./artists.component.scss']
})
export class ArtistComponent implements OnInit, OnDestroy {

  current_page: number = 1;
  list : any[] = [];
  listNew : any[] = [];
  previousPage: number = 0;
  listFinish = false;

  @ViewChild('modalComponent') modal:
  | ModalComponent<artistComponent>
  | undefined;
  private subscriptions: Array<Subscription> = [];

  role:string = ''

  constructor(
    private artistService: ArtistService,
    private cd: ChangeDetectorRef,
    private productService: ProductosService,
    private storage: StorageService,
    private spinner: SpinnerService,

  ) {  
      this.getRole(); 
      this.spinner.show()
    }

  ngOnInit(){
    this.loadArtist()
   }

  ngOnDestroy(){
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  selectedArtist(artist){
    
    this.productService.setArtist([
      {id: artist.id,
      name: artist.name,
      country:artist.country,
      description:artist.description,
      url:artist.url,
      style:artist.style
    }])
    this.close()
  }

  getRole(){
    this.subscriptions.push(
      this.storage.getRole()
      .subscribe((rol:string) => rol !== '' && (this.role = JSON.parse(rol)) )
    )
  }


  loadArtist(){
    if(this.role === 'store'){
      this.artistService.getFullListPage()
      .then(res => {
          this.list = res.response
          this.spinner.hide()
          this.cd.detectChanges()
        })
    }else{
      this.artistService.getFullListPageAdmin()
      .then(res => {
        this.list = res.response
        this.spinner.hide()
        this.cd.detectChanges()
      })
    }
  }


  async close(): Promise<void> {
    await this.modal?.close();
  }

}
