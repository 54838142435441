import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class StoreRegisterService {

  constructor(
    private httpService: HttpService,

  ) { }



  registerStore(data:object): Promise<any>{
      return this.httpService.post(`register`, data);
  }

  getTokenExtern(code:object): Promise<any>{
    return this.httpService.post(`verify`, code);
  }

  generatePDF(id){
    return this.httpService.post(`admin/exports/getMenu`,{id_store:id});

  }


}
